<template>
    <div class="session-wrapper">
        <div class="session-right text-xs-center" style="margin-left: unset !important;">
            <div class="session-table-cell">
                <div class="session-content">
                    <div class="text-center">
                        <img
                                :src="appLogo"
                                class="img-responsive mb-3"
                                width="150"
                                height="150"
                        />
                    </div>
                    <div class="text-center">
                        <v-icon large>far fa-hand-paper</v-icon>
                    </div>
                    <h2 class="text-center">You are not allowed to view this document.</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppConfig from "Constants/AppConfig";

    export default {
        name: "NoAccess",
        data() {
            return {
                appLogo: AppConfig.appSessionLogo
            };
        },
    }
</script>

<style scoped>

</style>